import { WaypointLabelRender } from "@/components/r3f/renderers/waypoint-label-render";
import { useWalkPlaceholderPositions } from "@/hooks/use-walk-placeholder-positions";
import { selectActiveElement } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  selectObjectVisibility,
  selectShouldShowWaypointsOnFloors,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { offsetPlaceholders } from "@/utils/offset-placeholders";
import {
  LocationPlaceholderDefault,
  LocationPlaceholderHover,
  PanoramaPlaceholder,
  parseVector3,
  useOverrideCursor,
  useSvg,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useMemo, useState } from "react";
import { Plane, Texture, Vector3 } from "three";
import {
  useVisiblePlaceholders,
  useWaypoints,
} from "../../hooks/use-placeholders";
import { selectBestModelCameraFor360 } from "./animations/pano-to-model";

/** Minimum distance to consider a placeholder click */
const MIN_PLACEHOLDER_CLICK_DISTANCE = 0.1;

export type WalkPlaceholdersProps = {
  /** All the placeholders for this pano */
  placeholders: IElementImg360[];

  /** The sheet to use to place the placeholders */
  sheetForElevation?: IElementGenericImgSheet;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Callback to signal a placeholder have been clicked */
  onPlaceholderClick?(element: IElementImg360, position: Vector3): void;

  /**
   * True to render the placeholders
   *
   * @default true
   */
  visible?: boolean;

  /**
   * True to enable the distance fade off of the placeholders
   *
   * @default false
   */
  shouldFadeOff?: boolean;
};

/**
 * @returns A component to render all the placeholders to navigate in panorama mode
 */
export function WalkPlaceholders({
  placeholders,
  sheetForElevation,
  onPlaceholderClick,
  clippingPlanes,
  shouldFadeOff = false,
  visible = true,
}: WalkPlaceholdersProps): JSX.Element | null {
  const [isHovered, setIsHovered] = useState(false);

  const defaultTexture = useSvg(LocationPlaceholderDefault, 512, 512);
  const hoverTexture = useSvg(LocationPlaceholderHover, 512, 512);

  // Placeholders will be shown at the scan position and they will face the camera if this option is disabled
  // Otherwise they are placed on the floor, looking up
  const shouldShowWaypointsOnFloor = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  const activeElement = useAppSelector(selectActiveElement);

  const placeholdersWithoutCurrentPano = useMemo(
    () =>
      placeholders.filter(
        (placeholder) => placeholder.id !== activeElement?.id,
      ),
    [activeElement?.id, placeholders],
  );

  // Use the scan positions if the option to show the waypoints on the floor is disabled
  const positions = useWalkPlaceholderPositions(
    placeholdersWithoutCurrentPano,
    sheetForElevation,
    !shouldShowWaypointsOnFloor,
  );

  const { visiblePlaceholders, visiblePositions } = useVisiblePlaceholders({
    placeholders: placeholdersWithoutCurrentPano,
    positions,
    clippingPlanes,
  });

  const { placeholdersOffset, shiftedPlaceholders } = useMemo(
    () => offsetPlaceholders(visiblePositions),
    [visiblePositions],
  );

  const waypoints = useWaypoints(visiblePlaceholders, visiblePositions);

  const store = useAppStore();
  const onWaypointClicked = useCallback(
    (pano: IElementImg360) => {
      if (onPlaceholderClick) {
        const position = selectBestModelCameraFor360(
          pano,
          sheetForElevation,
        )(store.getState());
        onPlaceholderClick(pano, parseVector3(position));
      }
    },
    [onPlaceholderClick, sheetForElevation, store],
  );

  const domElement = useThreeEventTarget();
  useOverrideCursor("pointer", isHovered, domElement);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  const shouldDisplayWaypointLabels = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypointLabels),
  );
  if (!visible || !shouldWayPointsBeVisible) {
    return null;
  }

  return (
    <>
      <group
        position={placeholdersOffset}
        name="placeholders"
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
      >
        {visiblePlaceholders.map((el, index) => (
          <WalkPlaceholder
            key={el.id}
            element={el}
            position={shiftedPlaceholders[index]}
            onWaypointClick={onWaypointClicked}
            defaultTexture={defaultTexture}
            hoverTexture={hoverTexture}
            shouldFadeOff={shouldFadeOff}
            shouldFaceCamera={!shouldShowWaypointsOnFloor}
          />
        ))}
      </group>
      {
        // Render waypoint labels
        shouldDisplayWaypointLabels && (
          <WaypointLabelRender
            waypoints={waypoints}
            onLabelClick={onWaypointClicked}
          />
        )
      }
    </>
  );
}

type WalkPlaceholderProps = Pick<WalkPlaceholdersProps, "shouldFadeOff"> & {
  /** The 360 element whose placeholder we want to render */
  element: IElementImg360;

  /** The floor position for this element */
  position: Vector3;

  /** Texture used for the default state */
  defaultTexture: Texture;

  /** Texture used for the hover state */
  hoverTexture: Texture;

  /** True if the placeholders should face the camera */
  shouldFaceCamera?: boolean;

  /** Callback to signal a placeholder have been clicked */
  onWaypointClick(element: IElementImg360): void;
};

/** @returns A img360 placeholder for walk mode */
function WalkPlaceholder({
  element,
  position,
  shouldFadeOff = false,
  shouldFaceCamera,
  defaultTexture,
  hoverTexture,
  onWaypointClick,
}: WalkPlaceholderProps): JSX.Element {
  const placeholderClicked = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      // Prevent the click event if the user clicks a placeholder too close to the camera
      // But don't stop the propagation, so that elements behind the placeholder can still be clicked
      if (ev.distance < MIN_PLACEHOLDER_CLICK_DISTANCE) {
        return;
      }
      ev.stopPropagation();
      onWaypointClick(element);
    },
    [element, onWaypointClick],
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  return (
    <PanoramaPlaceholder
      key={element.id}
      shouldFadeOff={shouldFadeOff}
      fadeDistance={visibilityDistance}
      shouldFaceCamera={shouldFaceCamera}
      position={position}
      defaultTexture={defaultTexture}
      hoverTexture={hoverTexture}
      onClicked={placeholderClicked}
    />
  );
}
