import { GUID } from "@faro-lotv/foundation";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { RefObject, useCallback, useRef } from "react";
import { PickingToolsRef } from "./picking-tools";
import { PointerMovePreviewHandlerRef } from "./pointer-move-preview-handler";

/** A set of callbacks that forward pointer events to the given reference to the picking tools */
export type PickingToolsCallbacks = {
  /** A reference to the PickingTools component */
  tools: RefObject<PickingToolsRef>;

  /** Pointer event handler that forwards the events to the picking tools reference */
  onModelHovered(ev: ThreeEvent<DomEvent>, iElementId: GUID): void;

  /** Pointer event handler that forwards the events to the picking tools reference */
  onModelClicked(ev: ThreeEvent<MouseEvent>, iElementId: GUID): void;

  /** Pointer event handler that forwards the events to the picking tools reference */
  onModelZoomed(ev: ThreeEvent<MouseEvent>, iElementId: GUID): void;

  /** A reference to the pointer move preview component */
  pointerMovePreview: RefObject<PointerMovePreviewHandlerRef>;
};

/** @returns pointer event handlers that forward events to a ref to the picking tools */
export function usePickingToolsCallbacks(): PickingToolsCallbacks {
  const tools = useRef<PickingToolsRef>(null);
  const pointerMovePreview = useRef<PointerMovePreviewHandlerRef>(null);

  const onModelHovered = useCallback(
    (ev: ThreeEvent<PointerEvent>, iElementId: GUID) => {
      if (pointerMovePreview.current) {
        pointerMovePreview.current.onModelHovered(ev);
      }
      if (tools.current) {
        tools.current.onModelHovered(ev, iElementId);
      }
    },
    [],
  );

  const onModelClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, iElementId: GUID) => {
      if (!tools.current) return;
      tools.current.onModelClicked(ev, iElementId);
    },
    [],
  );

  const onModelZoomed = useCallback(
    (ev: ThreeEvent<MouseEvent>, iElementId: GUID) => {
      if (!tools.current) return;
      tools.current.onModelZoomed(ev, iElementId);
    },
    [],
  );

  return {
    tools,
    onModelHovered,
    onModelClicked,
    onModelZoomed,
    pointerMovePreview,
  };
}
