import { RootState } from "../store";
import {
  OrientationOf2dOverviewOptions,
  ViewObjectTypes,
  WaypointsColoringOptions,
} from "./view-options-slice";

/**
 * @param type of object to check the visibility for
 * @returns if that type of objects should be visible
 */
export function selectObjectVisibility(type: ViewObjectTypes) {
  return ({ viewOptions }: RootState): boolean => viewOptions.visibility[type];
}

/** @returns the map of the objects visibilities */
export function selectObjectsVisibility({
  viewOptions,
}: RootState): Record<ViewObjectTypes, boolean> {
  return viewOptions.visibility;
}

/**
 * @returns the visibility distance of the project
 */
export function selectVisibilityDistance({ viewOptions }: RootState): number {
  return viewOptions.visibilityDistance;
}

/**
 * @returns how the waypoints should be colored: with the default color, by altitude, or by date
 */
export function selectWaypointsColoring({
  viewOptions,
}: RootState): WaypointsColoringOptions {
  return viewOptions.waypointColoring;
}

/**
 * @returns how the 2D overview should be oriented: with the sheet orientation or the project orientation
 */
export function selectOrientationOf2dOverview({
  viewOptions,
}: RootState): OrientationOf2dOverviewOptions {
  return viewOptions.orientationOf2dOverview;
}

/**
 * @returns if the waypoints should be rendered on the floor or at the scan height
 */
export function selectShouldShowWaypointsOnFloors({
  viewOptions,
}: RootState): boolean {
  return viewOptions.shouldShowWaypointsOnFloors;
}

/**
 * @returns if the pointermove preview should be rendered or not
 */
export function selectShouldShowPointerMovePreview({
  viewOptions,
}: RootState): boolean {
  return viewOptions.shouldShowPointerMovePreview;
}
