import { PreviewPlaneRenderer } from "@/components/r3f/renderers/preview-plane-renderer";
import { PointCloudObject } from "@/object-cache";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import { selectShouldShowPointerMovePreview } from "@/store/view-options/view-options-selectors";
import { setShouldShowPointerMovePreview } from "@/store/view-options/view-options-slice";
import {
  getPickedNormal,
  getPickedPoint,
} from "@faro-lotv/app-component-toolbox";
import { ThreeEvent } from "@react-three/fiber";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Vector3 } from "three";

export type PointerMovePreviewHandlerRef = {
  /** Forward model hovered event to the active tool */
  onModelHovered(ev: ThreeEvent<PointerEvent>): void;
};

type PointerMovePreviewHandlerProps = {
  // Point cloud object to use compute the plane orientation if normal is not provided by raycaster
  pointcloud?: PointCloudObject;
};

/**
 * @returns A preview handler that shows the model geometric feature hovered under the pointer.
 */
export const PointerMovePreviewHandler = forwardRef<
  PointerMovePreviewHandlerRef,
  PointerMovePreviewHandlerProps
>(function PointerMovePreviewTool({ pointcloud }, ref): JSX.Element | null {
  const showPointerMovePreview = useAppSelector(
    selectShouldShowPointerMovePreview,
  );

  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const [currentNormal, setCurrentNormal] = useState<Vector3>();

  useImperativeHandle(ref, () => ({
    onModelHovered(ev: ThreeEvent<PointerEvent>) {
      ev.stopPropagation();
      setCurrentPoint(getPickedPoint(ev));
      setCurrentNormal(getPickedNormal(ev));
    },
  }));

  if (!showPointerMovePreview || !currentPoint) {
    return null;
  }

  return (
    <PreviewPlaneRenderer
      position={currentPoint}
      normal={currentNormal}
      pointcloud={pointcloud ?? undefined}
    />
  );
});

/**
 * A hook that sets the pointer move preview visibility based on the active tool
 *
 * @param activeTool The active tool name
 */
export function usePointerMovePreview(activeTool: ToolName | null): void {
  const dispatch = useAppDispatch();
  // Enable the pointer move preview tool when the active tool is measurement or analysis
  useEffect(() => {
    dispatch(
      setShouldShowPointerMovePreview(
        activeTool === ToolName.measurement || activeTool === ToolName.analysis,
      ),
    );
  }, [activeTool, dispatch]);
}
