import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** Types of view objects to control the visibility for */
export enum ViewObjectTypes {
  waypoints = "waypoints",
  annotations = "annotations",
  measurements = "measurements",
  trajectories = "trajectories",
  analyses = "analyses",
  waypointLabels = "waypointLabels",
}

/** Options for waypoints colorization */
export enum WaypointsColoringOptions {
  default = "Default",
  byElevation = "By elevation",
  byCaptureDate = "By capture date",
}

/** Options for 2D overview orientation */
export enum OrientationOf2dOverviewOptions {
  sheet = "Sheet",
  project = "Project",
}

type ViewOptionsState = {
  /** Visibility of objects in the scene */
  visibility: Record<ViewObjectTypes, boolean>;
  /** Visibility distance for rendering the objects */
  visibilityDistance: number;
  /** Waypoints colorization option */
  waypointColoring: WaypointsColoringOptions;
  /** 2D overview orientation option */
  orientationOf2dOverview: OrientationOf2dOverviewOptions;
  /** True to render the waypoints on the floor. False to render them at the scan height */
  shouldShowWaypointsOnFloors: boolean;
  /** True to show a preview of geometric feature of the model on pointermove event */
  shouldShowPointerMovePreview: boolean;
};

/** Initial values for the ViewOptionsSlice */
export const VIEW_OPTIONS_INITIAL_VALUES: ViewOptionsState = {
  visibility: {
    waypoints: true,
    annotations: true,
    measurements: true,
    trajectories: true,
    analyses: true,
    waypointLabels: false,
  },
  visibilityDistance: 8,
  waypointColoring: WaypointsColoringOptions.byElevation,
  orientationOf2dOverview: OrientationOf2dOverviewOptions.sheet,
  shouldShowWaypointsOnFloors: true,
  shouldShowPointerMovePreview: false,
};

const viewOptionsSlice = createSlice({
  initialState: VIEW_OPTIONS_INITIAL_VALUES,
  name: "viewOptions",
  reducers: {
    /**
     * Update the waypoints visibility
     *
     * @param state current state
     * @param action new visibility for waypoints
     */
    setObjectVisibility(
      state,
      action: PayloadAction<{ type: ViewObjectTypes; visibility: boolean }>,
    ) {
      state.visibility[action.payload.type] = action.payload.visibility;
    },

    /**
     * Update the visibility distance when rendering objects in the 3D scene
     *
     * @param state current state
     * @param action the new visibility distance
     */
    setVisibilityDistance(state, action: PayloadAction<number>) {
      state.visibilityDistance = action.payload;
    },

    /**
     * Sets how to color the waypoints: by elevation, by capture date or default
     *
     * @param state current state
     * @param action waypoints colorization option
     */
    setWaypointsColoring(
      state,
      action: PayloadAction<WaypointsColoringOptions>,
    ) {
      state.waypointColoring = action.payload;
    },

    /**
     * Sets how to orient the 2D Overview: by sheet or by project
     *
     * @param state current state
     * @param action the 2D overview orientation option
     */
    setOrientationOf2dOverview(
      state,
      action: PayloadAction<OrientationOf2dOverviewOptions>,
    ) {
      state.orientationOf2dOverview = action.payload;
    },

    /**
     * Show waypoints on the floor or at the scan height
     *
     * @param state current state
     * @param action flag to decide if the waypoints should be shown on the floor
     */
    setShouldShowWaypointsOnFloors(state, action: PayloadAction<boolean>) {
      state.shouldShowWaypointsOnFloors = action.payload;
    },

    /**
     * Show a preview of geometric feature of the model on pointermove event
     *
     * @param state current state
     * @param action flag to decide if the pointermove preview should be shown
     */
    setShouldShowPointerMovePreview(state, action: PayloadAction<boolean>) {
      state.shouldShowPointerMovePreview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => VIEW_OPTIONS_INITIAL_VALUES);
  },
});

export const {
  setObjectVisibility,
  setVisibilityDistance,
  setWaypointsColoring,
  setOrientationOf2dOverview,
  setShouldShowWaypointsOnFloors,
  setShouldShowPointerMovePreview,
} = viewOptionsSlice.actions;

export const viewOptionsReducer = viewOptionsSlice.reducer;
